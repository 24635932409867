.order {
    max-width: 550px;
    font-size: -px-to-em(16,14);

    h2 {
        margin-bottom: 15px;
        font-size: -px-to-em(16,24);
        font-weight: 700;
        text-transform: uppercase;
        color: #227499; 
    }

    h3 {
        padding-left: 30px;
        margin-bottom: 15px;
        background: url(../image/customer.png) no-repeat left center;
        background-size: 21px 21px;
        font-size: -px-to-em(16,18);
        line-height: 21px;
        font-weight: 700;
        color: #f26228;
    }
    
    input[type="text"],
    input[type="email"],
    input[type="password"],
    select, 
    textarea {
        display: block;
        padding: 0 10px;
        background-color: #f4f4f4;
        border: 1px solid #ccc;
        border-radius: 3px;
        font-size: -px-to-em(14,13);
    }

    input[type="text"],
    input[type="email"],
    input[type="password"] {
        width: 100%;
        height: 30px;
    }

    textarea {
        width: 100%;
        padding: 10px;
        resize: none;
    }

    select {
        max-width: 100%;
        height: 30px;
        cursor: pointer;
    }

    label {
        display: block;
        margin-bottom: 6px;
        cursor: pointer;
    }

    p {
        margin-bottom: 6px;
    }

    label input {
        margin-right: 7px;
    }

    .input-container {
        margin-bottom: 14px;
    }

    .account-select {
        margin-bottom: 30px;

        h4 {
            margin-bottom: 10px;
        }
    }

    .primary-info {
        margin-bottom: 45px;
    }

    .two-columns {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .input-container {
            flex-basis: 260px;
            max-width: 260px;
        }
    }

    .required-input label {
        font-weight: 700;

        span {
            color: #dd2a34;
        }
    }
}