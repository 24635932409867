.catalog-nav {

    li {
        display: inline-block;
        padding: 3px 8px 4px;
        margin: 0 6px 10px 0;
        background-color: #227499;
        border-radius: 3px;
        text-align: center;
        font-size: -px-to-em(16,14);
        text-transform: uppercase;
        font-weight: 600;
        color: #fff;

    }
}