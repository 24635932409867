.submit-order {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 15px;

    .order-button {
        display: block;
        flex-basis: 135px;
        max-width: 135px;
        justify-self: flex-end;
        border-radius: 3px;
        background-color: #227499;
        line-height: 40px;
        text-align: center;
        font-size: -px-to-em(16,14);
        font-weight: 700;
        color: #fff;
        cursor: pointer;
        transition: .2s all;

        &:hover {
            background-color: #18536f;
        }
    }

    .terms { 
        margin: 0 16px 16px 0;

        label {
            display: inline-block;
        }
    }
}

@media all and (max-width: 550px) {
    .submit-order {
        flex-wrap: wrap;
    }
}

@media all and (max-width: 400px) {
    .submit-order .terms {
        font-size: .8em;
    }
}