body, html {
    position: relative;
    overflow-x: hidden;
}

body {
    width: 100%;
    max-width: 100%;
    min-height: 100vh;
    background-color: $wrapper-bg;
    background-image: url(../image/bg.jpg);
    background-repeat: no-repeat;
    background-size: 1500px auto;
    background-position: center center;
    background-attachment: fixed;
    font-family: 'Open Sans', sans-serif;
    font-size: 100%;
    font-weight: 400;
    font-style: normal;
    position: relative;
    color: #fff;
}

.wrapper {
    width: 100%;
    max-width: 960px;
    overflow-x: hidden;
    margin: 0 auto;
    background-color: $secondary-bg;
    box-shadow: 6px 0px 6px 0px rgba(0, 0, 0, 0.3),
                -6px 0px 6px 0px rgba(0, 0, 0, 0.3);
}

.block-img {
    display: block;
    width: 100%;
    max-width: 100%;
}

a {
    color: inherit;
    text-decoration: inherit;
    transition: 0.2s all;
}

h1 {
    font-size: -px-to-em(16,24);
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1em;

    a {
        display: block;
    }

    span {
        display: block;
        font-size: -px-to-em(24,20);
        font-weight: 300;
    }
}

.main {
    background-color: #fff;
    color: #535353;
    padding: 72px 30px 40px 40px;
    position: relative;
}

input, textarea, button, select {
    font-family: inherit;
    color: inherit;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
textarea:focus,
select:focus {
    outline-color: #00aeef;
}

input[type="radio"],
input[type="checkbox"] {
    outline: none;
}

.overlay,
#overlay {
    background-color: #123648;
}

.toggle-aside {
    display: none;
    position: relative;
    top: 6px;
    margin-bottom: 20px;
    background: $secondary-bg;
    padding: 4px 25px;
    border-radius: 3px;
    font-weight: 600;
    color: #fff;
}

@media all and (max-width: 740px) {
    .main {
        padding-left: 15px;
        padding-right: 15px;
    }

    .toggle-aside {
        display: inline-block;
    }
}