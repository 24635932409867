.catalog-table {
    margin: 10px 0 40px;
    width: 100%;
    overflow-x: scroll;

    table {
        width: 100%;
        min-width: 560px;
        border: 1px solid #ccc;
        font-size: -px-to-em(16,12);
    }

    tr {
        cursor: pointer;
    }

    tr.active td {
        background-color: #00aeef !important;
        color: #fff;
    }

    td {
        padding: 5px 14px;
        border-right: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        transition: .2s all;
    }

    td:nth-child(1), td:nth-child(2), td:nth-child(5) {
        font-size: -px-to-em(12,14);
        font-weight: 700;
    }
    
    .qty-container {
        display: flex;
        align-items: center;

        div {
            position: relative;
            margin: 0 11px 0 5px;
        }

        .quantity {
            display: block;
            padding: 0 8px;
            width: 50px;
            height: 24px;
            border: 1px solid #ccc;
            border-radius: 3px;
            color: #535353;
            background-color: #f4f4f4;
            font-weight: 600;
        }

        .add-qty {
            appearance: none;
            background-color: transparent;
            display: block;
            position: absolute;
            width: 0;
            height: 0;
            right: 4px;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            cursor: pointer;
            outline: none;
        }

        .qty-plus {
            top: 6px;
            border-bottom: 5px solid #535353;
        }

        .qty-minus {
            bottom: 6px;
            border-top: 5px solid #535353;
        }

    }

    .add-to-cart {
        display: block;
        appearance: none;
        width: 19px;
        height: 17px;
        background: url(../image/add-to-cart.png) no-repeat;
        background-size: 19px 17px;
        outline: none;
        cursor: pointer;
    }

    .active .add-to-cart {
        background-image: url(../image/add-to-cart-selected.png);
    }
}