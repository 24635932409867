.wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;

    header, footer {
        flex-basis: 100%;
    }
    
    & > aside {
        flex-basis: 25%;
        max-width: 25%;
    }

    .main {
        flex-basis: 75%;
        max-width: 75%;
    }
}

@media all and (max-width: 740px) {
    .wrapper {
        & > aside {
            width: 240px;
            max-width: 240px;
            flex-basis: 240px;
            position: absolute;
            left: 0;
            transform: translateX(-240px);
            background-color: #18536F;
            transition: transform 0.25s cubic-bezier(0.46, 0.03, 0.52, 0.96);

            &.open-aside {
                transform: translateX(0);
            }
        }

        .open-aside ~ .main {
            transform: translateX(240px);
        }

        .main {
            flex-basis: 100%;
            max-width: 100%;
            transition: transform 0.25s cubic-bezier(0.46, 0.03, 0.52, 0.96);
        }
    }
}