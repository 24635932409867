@function -px-to-em($parent-size, $child-size) {
    @return $child-size / $parent-size + em;
}

@function -px-to-procent($parent-size, $child-size) {
    @return ($child-size / $parent-size) * 100 + %;
}

@function -col-with-margins($n, $parent-size, $margin-size) {
    @return 100*(($parent-size - ($n - 1) * $margin-size)/$n)/$parent-size + %;
}