.checkout {

    h2 {
        margin-bottom: 15px;
        font-size: -px-to-em(16,24);
        font-weight: 700;
        text-transform: uppercase;
        color: #227499; 
    }

    h3 {
        padding-left: 30px;
        margin-bottom: 15px;
        background: url(../image/cart-page.png) no-repeat left center;
        background-size: 21px 21px;
        font-size: -px-to-em(16,18);
        line-height: 21px;
        font-weight: 700;
        color: #535353;
    }

    .cart-table form {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
    }

    .table-container {
        overflow-x: scroll;
    }

    .cart {
        min-width: 450px;
    }

    table {
        border: 1px solid #ccc;
        color: #535353;
        font-size: -px-to-em(16,14);

        td {
            padding: 8px 10px;
            border-right: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
        }

        thead td {
            background-color: #ddd;
            text-transform: uppercase;
            font-weight: 700;
        }
    }

    thead tr:last-child td {
        border-bottom: 1px solid #ccc;
    }
    
    .cart {
        width: 100%;
    }

    .cart tbody {

        td:nth-child(1) {
            font-size: -px-to-em(14,13);
            font-weight: 700;
        }

        td:nth-child(2) {
            min-width: 50px;
        }

        td:nth-child(3) {
            min-width: 100px;
        }

        td:nth-child(2), td:nth-child(3) {
            font-size: -px-to-em(14,12);
        }

        td:last-child {
            font-weight: 700;
        }

        td:nth-child(3), td:last-child {
            text-align: right;
        }
    }

    .quantity {
        display: flex;

        button, input {
            display: block;
            width: 28px;
            height: 28px;
            background-color: #f4f4f4;
            border: 1px solid #ccc;
            outline: none;
        }

        .qty {
            border-right: none;
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
            text-align: center;
            font-size: -px-to-em(14,12);
            font-weight: 700;
        }

        .reload, .delete {
            background-size: 12px 12px;
            background-repeat: no-repeat;
            background-position: center;
            cursor: pointer;
        }

        .reload {
            border-right: none;
            background-image: url(../image/reload.png);

            &:hover {
                background-image: url(../image/reload-hover.png);
            }
        }

        .delete {
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
            background-image: url(../image/delete.png);

            &:hover {
                background-image: url(../image/delete-hover.png);
            }
        }
    }

    .total {
        width: 260px;
        margin-top: 30px;

        td:first-child {
            text-align: right;
            font-weight: 700;
        }

        tr:last-child td {
            font-weight: 700
        }
    }

    .submit-order {
        justify-content: flex-end;
    }
}