.login-form {
    display: block;
    position: absolute;
    right: 0;
    bottom: 0px;
    width: 250px;
    padding: 15px 30px 20px;
    border-bottom-left-radius: 2px;
    transform: translateY(calc(100% - 45px));
    background-color: $secondary-bg;
    visibility: hidden;
    opacity: 0;
    transition: .5s all;
    z-index: 3;

    input {
        display: block;
        height: 24px;
        padding: 0 10px;
        border-radius: 1px;
        font-size: -px-to-em(16,13);
        font-weight: 600;
    }

    input:not([type="submit"]) {
        width: 100%;
        background-color: #fff;
        color: #838383;
        margin-bottom: 6px;
    }

    input[type="submit"] {
        padding: 0 14px;
        background-color: #227499;
        text-transform: uppercase;
        color: #fff;
        cursor: pointer;
    }

    a {
        display: block;
        margin: 9px 0;
        font-size: -px-to-em(16,13);
        text-decoration: underline;
        color: #56788c;
        font-weight: 600;
    }

    &.open-form {
        visibility: visible;
        opacity: 1;
    }
}

@media all and (max-width: 740px) {
    header .login-form {
        bottom: -45px;
    }
}