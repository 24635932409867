header {
    padding: 42px 0 0 30px;
    position: relative;

    .logo {
        display: block;
        position: absolute;
        top: 20px;
        width: 175px;
        height: 200px;
    }

    .content {
        padding: 0 30px 35px 0;
        margin: 0 0 16px 230px;
        position: relative;

        time {
            position: absolute;
            bottom: 0;
            left: 0;
            text-transform: uppercase;
            font-size: -px-to-em(16,11);
            color: #56788c;
            font-weight: 600;
        }
    }

    .sign-buttons {
        position: absolute;
        bottom: 0;
        right: 30px;

        a {
            display: inline-block;
            font-size: -px-to-em(16,13);
            font-weight: 600;
            line-height: 1em;
            text-decoration: underline;

            &:first-child {
                border-right: 1px solid #fff;
                padding-right: 5px;
            }

            &:hover, &.open-form {
                color: #02a4e1;
            }
        }
    }

    .nav-container {
        background-color: #227499;
    }

    nav ul {
        font-size: -px-to-em(16,14);

        li {
            display: block;
            
            &:first-child a {
                padding-left: 20px;
            }
        }

        a {
            display: block;
            padding: 11px 10px 10px;
        }
    }

    form.search-form {
        position: relative;
        flex-basis: 190px;
        height: 24px;

        .search {
            display: block;
            width: 100%;
            height: 100%;
            padding: 0 24px 0 10px;
            border-radius: 1px;
            background-color: $secondary-bg;
            font-size: -px-to-em(16,14);
            font-weight: 300;
            color: #fff;

            &::placeholder {
                color: #fff;
            }
        }

        input[type="submit"] {
            display: block;
            position: absolute;
            width: 15px;
            height: 15px;
            right: 8px;
            top: 4.5px;
            cursor: pointer;
            background: url(../image/search.png) no-repeat center;
            background-size: 100% 100%;

            &:focus {
                outline: none;
            }
        }
    }

    .cart > span {
        display: none;
    }

    .dropdown-cart {
        display: none;
    }

    .toggle-menu {
        display: none;
    }
}

@media all and (max-width: 960px) {
    header {
        padding-left: 0;

        .logo {
            width: 100px;
            height: auto;
            top: 10px;
            left: 30px;
        }

        .content {
            margin: 0 0 25px 170px;
        }

        .nav-container {
            padding-left: 30px;
            margin-left: 0;
        }

        nav ul li:first-child a {
            padding-left: 10px;
        }

        .cart:hover .dropdown-cart {
            opacity: 0;
            visibility: hidden;
        }
    }
}

@media all and (max-width: 740px) {
    header .nav-desktop {
        display: none;
    }

    header .nav-mobile {
        display: block;
    }

    header .toggle-menu {
        display: block;
    }
}

@media all and (max-width: 650px) {
    header .content {
        padding-right: 80px;
    }
}

@media all and (max-width: 500px) {
    header {
        padding: 10px 0 30px;
    }

    header .logo {
        width: 140px;
        position: static;
        margin: 0 auto 20px;
    }

    header .content {
        margin: 0;
        padding: 0 30px;

        time {
            display: none;
        }
    }

    header .sign-buttons {
        bottom: auto;
    }
}