.border-radius-table {
    border-radius: 3px;

    td:last-child {
        border-right: none;
    }

    tr:last-child td {
        border-bottom: none;
    }
}