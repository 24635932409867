.article-page {

    h2, h3 {
        color: #227499;
        font-weight: 700;
    }

    h2 {
        font-size: -px-to-em(16,24);
        margin-bottom: 15px;
    }

    h3 {
        font-size: -px-to-em(16,18);
    }

    h4 {
        font-weight: 700;
        font-size: -px-to-em(16,14);
        color: #535353;

        span {
            color: #f26228
        }
    }

    p {
        margin-top: 8px;
        font-size: -px-to-em(16,14);
        line-height: 24px;
    }
}