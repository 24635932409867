.nav-desktop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px 0 160px;
    margin-left: 50px;

    ul {
        display: flex;
        flex-basis: 450px;
        justify-content: flex-start;

        li a {
            text-align: center;

            &:hover {
                background-color: $secondary-bg;
            }
        }
    }

    .cart {
        position: relative;

        & > span {
            display: block;
            position: absolute;
            width: 20px;
            height: 20px;
            top: -10px;
            right: -10px;
            border-radius: 10px;
            background-color: #00aeef;
            text-align: center;
            line-height: 20px;
            font-weight: 700;
            font-size: -px-to-em(16,11);
        }

        &:hover {
            background-color: $secondary-bg;

            .dropdown-cart {
                visibility: visible;
                opacity: 1;
            }
        }
    }

    .dropdown-cart {
        display: block;
        position: absolute;
        width: 180px;
        padding: 14px;
        background-color: $secondary-bg;
        text-align: left;
        visibility: hidden;
        opacity: 0;
        transition: .2s all;
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
        z-index: 3;

        p {
            font-size: -px-to-em(16,13);
            font-weight: 600;
        }

        .quantity, p span {
            color: #00aeef;
        }

        .quantity {
            margin: 5px 0 2px;
            text-decoration: underline;
        }
    }
}