.main-page {

    padding-right: 0;

    section:not(.table) {
        padding-right: 30px;
    }

    h2, h3 {
        color: #227499;
        font-weight: 700;
    }

    h2 {
        font-size: -px-to-em(16,24);
        margin-bottom: 15px;
    }

    h3 {
        font-size: -px-to-em(16,18);
    }

    h4 {
        font-weight: 700;
        font-size: -px-to-em(16,14);
        color: #535353;

        span {
            color: #f26228
        }
    }

    p {
        margin-top: 8px;
        font-size: -px-to-em(16,14);
        line-height: 24px;
    }

    .hours {
        margin-top: 60px;
        padding: 4px 0 0 80px;
        background: url(../image/clock.png) no-repeat left top;
        background-size: 58px 58px;

        h3 {
            color: #f26228;
            margin-bottom: 16px;
        }
    }

    .table {
        margin-top: 45px;

        .content {
            margin-top: 15px;
            background: url(../image/table-bg.png) no-repeat;
            background-position: right top;
            background-size: 430px 100%;
        }

        table {
            font-size: 1em;
            font-weight: 700;
            border: 1px solid #ccc;
            width: 255px;
            background-color: #fff;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;

            td {
                border-right: 1px solid #ccc;
                border-bottom: 1px solid #ccc;
                height: 40px;
                padding: 0 14px;
            }


            td:nth-child(2) {
                text-align: center;
                border-right: none;
            }

            tr:last-child td {
                border-bottom: none;
            }
        }
    }

    .area {
        margin-top: 60px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        h3 {
            flex-basis: 100%;
            margin-bottom: 15px;
        }

        .map {
            flex-basis: 330px;
            height: 240px;
            background-color: #000;
            
        }

        ul {
            flex-basis: 300px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-top: 15px;

            li p {
                margin: 0;
                font-weight: 600;
                font-size: -px-to-em(16,13);
                line-height: 15px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        
        .note {
            width: 100%;
            font-size: -px-to-em(16,14);
            font-weight: 700;
            color: #dd2a34;
            margin-top: 15px;
        }
    }

    .partners {
        margin-top: 60px;

        h3 {
            text-transform: uppercase;
            color: #535353;
            margin-bottom: 15px;
        }

        .carousel {
            position: relative;
            padding: 10px 28px;
            background-color: $secondary-bg;

            .owl-item {
                margin-right: 10px;
            }

            .control a {
                display: block;
                position: absolute;
                top: 0;
                width: 28px;
                height: 100%;
                background-repeat: no-repeat;
                background-size: 9px 18px;
                background-position: center center;
                opacity: .5;

                &:hover {
                    opacity: 1;
                }

                &.prev {
                    left: 0;
                    background-image: url(../image/carousel-arrow.png);
                }

                &.next {
                    right: 0;
                    background-image: url(../image/carousel-arrow.png);
                    transform: rotate(180deg);
                }
            }
        }
    }
}

@media all and (max-width: 700px) {
    .main-page {

        .area .map {
            margin-bottom: 15px;
        }
    }
}

@media all and (max-width: 450px) {
    .main-page {
        .hours {
            padding-left: 0;
            background: none;
        }
    }
}