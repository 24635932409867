.control-catalog {
    display: flex;
    justify-content: space-between;
    font-size: -px-to-em(16,14);
    font-weight: 700;

    .catalog-control {
        display: inline-block;
        padding: 10px 50px 10px 18px;
        color: #eee;
        border-radius: 3px;
        transition: .2s all;
    }

    .to-cart {
        background-color: #f26228;
        background-image: url(../image/go-to-cart.png);
        background-repeat: no-repeat;
        background-size: 22px 21px;
        background-position: 100px center;
        margin-right: 16px;
        margin-bottom: 16px;

        &:hover {
            background-color: #b9552c;
        }
    }

    .add-item {
        background-color: #227499;
        position: relative;

        &::after {
            display: block;
            position: absolute;
            content: '+';
            width: 21px;
            height: 21px;
            top: calc(50% - 10.5px);
            right: 18px;
            background-color: #eee;
            border-radius: 21px;
            font-size: -px-to-em(16,22);
            font-weight: 800;
            color: #227499;
            text-align: center;
            line-height: 21px;
        }

        &:hover {
            background-color: #18536f;
        }
    }
}

@media all and (max-width: 560px) {
    .control-catalog {
        flex-wrap: wrap;

        div span {
            display: none;
        }
    }
}