.catalog {

    h2 {
        margin-bottom: 15px;
        color: #227499;
        font-size: -px-to-em(16,24);
        text-transform: uppercase;
        font-weight: 700;
    }

    .print-button {
        display: block;
        position: absolute;
        top: 80px;
        right: 30px;
        width: 32px;
        height: 32px;
        background: url(../image/print.png) no-repeat;
        background-size: 15px 17px;
        background-position: center;
    }
}