footer {
    padding: 30px;
    background-color: $secondary-bg;

    p {
        text-align: right;
        font-size: -px-to-em(16,13);
        font-weight: 600;
        color: rgba(255,255,255,.2);

        a {
            text-decoration: underline;
            color: rgba(255,255,255,.5);
        }
    }
}