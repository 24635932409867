.nav-mobile {
    display: none;
    width: 320px;
    min-height: 100vh;
    height: 100%;
    padding: 50px 30px;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 10;
    transform: translateX(320px);
    transition: transform 0.25s cubic-bezier(0.46, 0.03, 0.52, 0.96);

    &.open-menu {
        transform: translateX(0);
    }

    li a {
        font-weight: 600;
    }

    .cart > span {
        display: none;
    }

    form.search-form {
        margin-top: 20px;
    }
}