.close-button {
    display: block;
    background: transparent;
    border: none;
    width: 15px;
    height: 12px;
    padding: 0;
    outline: none;
    position: relative;
    z-index: 30;
    cursor: pointer;

    &:before, &:after {
        display: block;
        content: '';
        position: absolute;
        height: 3px;
        width: 100%;
        left: 0;
        top: 50%;
        transform-origin: 50% 50%;
        transition: all 0.25s cubic-bezier(0.46, 0.03, 0.52, 0.96);
        background: #fff;
    }

    &:before {
        transform: translate3d(0, -10px, 0);
        transform: rotate3d(0, 0, 1, 45deg);
    }

    &:after {
        transform: translate3d(0, 10px, 0);
        transform: rotate3d(0, 0, 1, -45deg);
    }

    span {
        position: absolute;
        width: 100%;
        height: 3px;
        left: 0;
        overflow: hidden;
        background: #fff;
        text-indent: 200%;
        transition: all 0.25s;
        opacity: 0;
    }
}