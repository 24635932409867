aside {
    padding: 60px 0 0;
    position: relative;
    z-index: 1;

    h2 {
        font-size: 1em;
        font-weight: 800;
        color: #00aeef;
    }

    & > h2 {
        
        padding: 20px 0 0 30px;

        span {
            display: inline-block;
            padding-bottom: 15px;
            border-bottom: 1px solid rgba(255,255,255,.2);
        }
        
    }

    ul {
        padding: 10px 0;
    }
    
    & > ul::after {
        display: block;
        content: '';
        width: 160px;
        height: 1px;
        background-color: rgba(255,255,255,.2);
        margin: 10px 0 0 30px;

    }

    li  {
        position: relative;

        &:hover > a {
            background-color: #227499;
        }

        &.with-submenu > a::after {
            top: 9px;
            right: 10px;
            width: 6px;
            height: 12px;
            background: url(../image/carousel-arrow.png) no-repeat;
            background-size: 100%;
            transform: rotate(180deg);
            opacity: .4;
        }

        &.with-submenu:hover .submenu {
            visibility: visible;
            opacity: 1;
        }
        
        &:hover > a::before, &:hover > a::after {
            opacity: 1;
        }

        a {
            display: block;
            width: 100%;
            padding: 7px 25px 5px 50px;
            font-size: -px-to-em(16,13);
            text-transform: uppercase;
            font-weight: 600;
            
            &::before, &::after {
                display: block;
                content: '';
                position: absolute;
                transition: 0.2s opacity;
            }

            &::before {
                left: 30px;
                top: 12.5px;
                width: 5px;
                height: 5px;
                border-radius: 5px;
                background-color: #fff;
                opacity: .5;
            }
        }
    }

    .submenu {
        position: absolute;
        width: 210px;
        right: -210px;
        top: -10px;
        background-color: #14455c;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
        visibility: hidden;
        opacity: 0;
        transition: .2s all;

        li a {
            padding-left: 20px;
            padding-right: 25px;

            &::before {
                display: none;
            }
        }
    }

    .cart {
        display: block;
        margin-left: 30px;
        padding: 5px 25px 0 38px;
        background-image: url(../image/cart.png);
        background-size: 24px 24px;
        background-position: top left;
        background-repeat: no-repeat;

        p {
            font-size: -px-to-em(16,13);
            font-weight: 600;
        }

        .quantity, p span {
            color: #00aeef;
        }

        .quantity {
            margin: 5px 0 2px;
            text-decoration: underline;
        }
    }

    .close-button {
        display: none;
        position: absolute;
        right: 10px;
        top: 84px;
    }
}

@media all and (max-width: 740px) {
    aside {
        .close-button {
            display: block;
        }

        li.with-submenu:hover .submenu {
            visibility: hidden;
            opacity: 0;
        }
    }
}